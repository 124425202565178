import { styled } from 'utils/emotion';

export const HrStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  marginTop: -1,
  zIndex: 1,
  opacity: 0.2,
  width: '100%',
  borderTop: `1px solid ${theme.background}`,
}));

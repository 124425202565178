import React from 'react';
import { graphql } from 'gatsby';
import SwitchTheme from 'components/SwitchTheme';

import { HrStyled } from './HrStyled';

const Hr = ({ pageBuilder }) => {
  const { hrTheme } = pageBuilder;

  return (
    <SwitchTheme name={hrTheme}>
      <HrStyled />
    </SwitchTheme>
  );
};

export default Hr;

export const query = graphql`
  fragment PageBuilderHrQuery on Craft_PageBuilderHr {
    hrTheme
  }
`;
